
.intro {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 70px;
  margin: auto;
  padding-top: 200px;
  padding-bottom: 40px;
  color: white
}
@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
.landing-title {
  animation-duration: 2s;
  animation-name: bounceIn;
}
